@if (form) {
  <form [formGroup]="form">
    <div class="inline-content">
      <shared-calendar-dropdown name="date" formControlName="date" [minDate]="minDate" [maxDate]="maxDate" />
      @if (dateTimeDelimiter) {
        <span>{{ dateTimeDelimiter }}</span>
      }
      @if (displayTime) {
        <shared-time-picker name="time" formControlName="time" />
      }
    </div>
    @if (showErrors && form.invalid && (form.dirty || form.touched)) {
      @if (f.date.errors?.required) {
        <mat-error class="error-message">Date is required</mat-error>
      } @else if (f.date.errors?.dateOutOfRange) {
        <mat-error class="error-message">Date out of range</mat-error>
      } @else if (f.time.errors?.required) {
        <mat-error class="error-message">Time is required</mat-error>
      } @else if (f.time.errors?.invalidTime) {
        <mat-error class="error-message">Time is invalid</mat-error>
      } @else {
        <mat-error class="error-message">Invalid date or time</mat-error>
      }
    }
  </form>
}
