<aside id="side-menu" class="menu-content" [ngClass]="{ expanded: isMenuExpanded }">
  @if (menuSettings$ | async; as menuSettings) {
    <div class="floating-container" [ngClass]="{ expanded: isMenuExpanded }">
      <!-- menu header component -->
      <div id="menu-header" [ngClass]="{ scrolled: isScrolled, expanded: isMenuExpanded }">
        @if ((parentRoute$ | async) == null) {
          <!-- mobile or collapsed -->
          @if (isMobile || !isMenuExpanded) {
            <div class="menu-header-logo-mobile">
              <a [routerLink]="rootRoute" class="mobile-logo">
                <img src="assets/svg/pointee_mobile.svg" alt="Pointee mobile logo" />
              </a>
            </div>
          }
          <!-- expanded -->
          @if (isMenuExpanded) {
            <!-- desktop logo -->
            <div class="menu-header-logo">
              <a [routerLink]="rootRoute" class="brand-logo">
                <img src="assets/svg/pointee_primary.svg" alt="Pointee brand logo" />
              </a>
            </div>
            <!-- action button -->
            @if (allowOrganizationSelect) {
              <button class="icon-btn" [preventFocus]="'OnceAfterClick'" [tooltip]="'Switch to another tenant'" (click)="displayOrganizationSelect()">
                <svg-icon key="tenant" size="md"></svg-icon>
              </button>
            }
          }
        }
        @if (parentRoute$ | async; as parentRoute) {
          <div class="menu-subheader-container">
            <a [routerLink]="parentRoute" class="icon-btn link" (click)="reloadPage(parentRoute)">
              @if (isMenuExpanded) {
                <svg-icon key="exit" fontSize="16px"></svg-icon><span>&nbsp;Exit&nbsp;{{ firstLabel$ | async }}</span>
              } @else {
                <svg-icon key="exit" fontSize="24px"></svg-icon>
              }
            </a>
          </div>
        }
      </div>
      <!-- menu body content -->
      <div #menuBodyContainer id="menu-body" [ngClass]="{ expanded: isMenuExpanded }" (scroll)="onBodyContainerScroll($event)">
        <div class="menu-body-container">
          @for (section of menuSettings.sections; track section; let isFirst = $first) {
            <div [ngClass]="{ 'action-wrapper': section.type === 'actions' }">
              <div class="menu-section">
                @if (section.type === 'items') {
                  <span class="menu-section-title">{{ section.label }}</span>
                }
                @if (!isFirst && (!isMenuExpanded || section.type === 'actions')) {
                  <span class="separator"></span>
                }
                @for (menuItem of section.items; track menuItem) {
                  <a
                    class="menu-item"
                    [routerLink]="menuItem.route || undefined"
                    [routerLinkActive]="['active']"
                    [routerLinkActiveOptions]="{ exact: false }"
                    [ngClass]="{ active: menuItem.active, disabled: menuItem.disabled }"
                    [style.pointer-events]="menuItem.disabled ? 'none' : 'auto'"
                    [style.display]="menuItem.hide === true ? 'none' : 'flex'"
                    [tooltip]="menuItem.label"
                    [display]="!isMenuExpanded"
                    (click)="menuItem.onClick && menuItem.onClick($event)"
                  >
                    <span class="menu-item-link">
                      <svg-icon key="{{ isMenuExpanded ? menuItem.icon + '-solid' : menuItem.icon }}" [ngClass]="{ large: !isMenuExpanded }"></svg-icon>
                      @if (isMenuExpanded) {
                        <span class="item-label menu-item-label prevent-select">{{ menuItem.label }}</span>
                      }
                      @if (menuItem.badge$ | async; as badge) {
                        @if (isBadgeVisible(menuItem, badge)) {
                          @if (menuItem.badgeType === 'warning') {
                            <div class="badge warning" [class.large]="isMenuExpanded">{{ badge }}</div>
                          }
                          @if (menuItem.badgeType === 'count' && isMenuExpanded) {
                            <span class="menu-item-count">{{ badge }}</span>
                          }
                        }
                      }
                    </span>
                  </a>
                }
              </div>
            </div>
          }
        </div>
      </div>
      <!-- end of menu body content -->
      <!-- menu footer -->
      <div id="menu-footer" [ngClass]="{ scrolled: isNextScrolled, expanded: isMenuExpanded }">
        <!-- user menu -->
        @if (isMenuExpanded) {
          <div class="user-menu-button" [matMenuTriggerFor]="profileMenu">
            <svg-icon key="account-24" size="lg"></svg-icon>
            <span class="account-name">{{ user?.userName }}</span>
            <svg-icon key="chevron-down"></svg-icon>
          </div>
        } @else {
          <div class="footer-action-buttons">
            @if (allowOrganizationSelect) {
              <button class="icon-btn" (click)="displayOrganizationSelect()" [tooltip]="'Switch to another tenant'">
                <svg-icon key="tenant-24" size="lg"></svg-icon>
              </button>
            }
            <button class="icon-btn" [matMenuTriggerFor]="profileMenu" [tooltip]="'Account'">
              <svg-icon key="account-24" size="lg"></svg-icon>
            </button>
          </div>
        }
        <!-- action button -->
        @if (allowExpandMenu) {
          <div class="footer-collapse-button-container">
            <button class="icon-btn" (click)="toggleMenuExpanded()" [tooltip]="!isMenuExpanded ? 'Expand menu' : 'Collapse menu'" tooltipHideOnClick>
              @if (isMenuExpanded) {
                <svg-icon key="expand-24-solid"></svg-icon>
              } @else {
                <svg-icon key="expand-24" size="lg"></svg-icon>
              }
            </button>
          </div>
        }
      </div>
    </div>
  }
</aside>

<mat-menu #profileMenu="matMenu" class="pointee-mat-menu">
  <ng-template matMenuContent>
    <div (click)="$event.stopPropagation(); $event.preventDefault()" class="flex-col p-l-16 p-r-16">
      <h4>{{ user.userName }}</h4>
      <p>{{ user.email }}</p>
    </div>
    <hr class="no-margin-padding" />
    @if (isSystemAdmin) {
      <button mat-menu-item (click)="gotoAdministrationPage()"><svg-icon key="admin"></svg-icon>Pointee administration</button>
      <button mat-menu-item (click)="gotoDesignSystemOverview()"><svg-icon key="design-system"></svg-icon>Design system</button>
      <hr class="no-margin-padding" />
    }
    <!-- <button mat-menu-item *ngIf="areNotificationSettingsEnabled" (click)="openNotificationSettings()"><svg-icon key="alarm-bell"></svg-icon>Notifications settings</button> -->
    @if (isUserPasswordLoginEnabled) {
      <button mat-menu-item (click)="changePassword()"><svg-icon key="password"></svg-icon>Change password</button>
    }
    <button mat-menu-item (click)="signOut()"><svg-icon key="logout-16"></svg-icon>Sign out</button>
  </ng-template>
</mat-menu>
