import { SelectItem, SelectItemType } from './select-item.type';

export type Selection = SelectItem | SelectItem[];

export class SelectionHelper {
  static createFixedItem(id: string, label: string, type: SelectItemType): SelectItem {
    return { id, label, type, isFixed: true };
  }

  static allSelected(selection: Selection): boolean {
    return Array.isArray(selection) && selection.some(i => i.type === SelectItemType.All);
  }

  static getItemIds(selection: Selection): string[] {
    return Array.isArray(selection) ? selection.filter(i => i?.isFixed != true).map(i => i.id) : [];
  }
}
