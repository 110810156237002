import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, NavigationExtras, NavigationStart, ParamMap, Params, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Permission } from '../enums/generated.enums';
import { ModuleType, PageType, SubPageType } from '../enums/page-type.enum';
import { ParamMapHelper } from '../helpers/param-map.helper';
import { TextHelper } from '../helpers/text.helper';
import { AuthService } from './auth.service';
import { LoggingService } from './logging.service';
import { PopupService } from './popup.service';
import { DateHelper } from '../helpers/date.helper';

@Injectable({ providedIn: 'root' })
export class NavigationService implements OnDestroy {
  private currentUrl: string = null;
  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();

  private _currentModule: ModuleType;
  private _currentPage: PageType;
  private _currentSubPage: SubPageType;
  private _currentParams: ParamMap;
  private _navigationStartTime: number;
  private _navigationEventsSubscribe: Subscription;

  get currentModule(): ModuleType {
    return this._currentModule;
  }

  get currentPage(): PageType {
    return this._currentPage;
  }

  get currentSubPage(): SubPageType {
    return this._currentSubPage;
  }

  get currentParams(): ParamMap {
    return this._currentParams;
  }

  get previousUrlValue(): string {
    return this.previousUrl.value;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private loggingService: LoggingService,
    private popupService: PopupService
  ) {
    this._navigationStartTime = Date.now();
    this._navigationEventsSubscribe = this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
        this._navigationStartTime = Date.now();
      }
      if (e instanceof NavigationEnd) {
        this.setCurrentRoute(this.getCurrentRoute(this.route.snapshot));
        this.previousUrl.next(this.currentUrl);
        this.currentUrl = e.url;
        this.trackPage(e);
      }
    });
  }

  ngOnDestroy(): void {
    this._navigationEventsSubscribe.unsubscribe();
  }

  clear(): void {
    this._currentModule = null;
    this._currentPage = null;
    this._currentSubPage = null;
    this._currentParams = null;
  }

  setCurrentRoute(route: ActivatedRouteSnapshot): void {
    this._currentParams = route.paramMap;
    const paramsSet = new Set();
    // check if there are any params in the route otherwise take paramMap from route.parent
    const paramMap = route.paramMap.keys.length > 0 ? route.paramMap : route.parent.paramMap;
    paramMap.keys.forEach(k => paramsSet.add(paramMap.get(k)));
    route = route.root;

    const segments = [];
    while (route) {
      for (const u of route.url) {
        if (!paramsSet.has(u.path)) {
          segments.push(u.path);
        }
      }
      route = route.firstChild;
    }

    this.setModulePageSubPage(segments);
  }

  private setModulePageSubPage(segments: string[]): void {
    const [moduleSegment, pageSegment, subPageSegment] = segments;

    if (this.isValidModuleType(moduleSegment)) {
      this._currentModule = moduleSegment;
    }

    if (this.isValidPageType(pageSegment)) {
      this._currentPage = pageSegment;
    }

    if (subPageSegment && this.isValidSubPageType(subPageSegment)) {
      this._currentSubPage = subPageSegment;
    }
  }

  private isValidModuleType(value: string): value is ModuleType {
    return Object.values(ModuleType).includes(value as ModuleType);
  }

  private isValidPageType(value: string): value is PageType {
    return Object.values(PageType).includes(value as PageType);
  }

  private isValidSubPageType(value: string): value is SubPageType {
    return Object.values(SubPageType).includes(value as SubPageType);
  }

  isOnPage(page: PageType): boolean {
    return this.currentPage == page;
  }

  isOnSubPage(subPage: SubPageType): boolean {
    return this.currentSubPage == subPage;
  }

  getRootRoute(): string[] {
    return this.getRoute();
  }

  getCurrentPageRoute(processId: string): string[] {
    return this.getOrganizationRoute(this._currentModule, this._currentPage, this.currentSubPage, processId);
  }

  getCurrentUrl(): string {
    return `${window.location.origin}${this.router.url}`;
  }

  async getFragmentUrl(fragment: string, clearFragment = true): Promise<string> {
    if (clearFragment) {
      await this.removeFragment();
    }
    const currentUrl = this.getCurrentUrl();
    return `${currentUrl}#${fragment}`;
  }

  async replaceUrl(url: string): Promise<void> {
    await this.router.navigateByUrl(url, { replaceUrl: true });
  }

  scrollToFragment(fragment: string): void {
    const element = document.getElementById(fragment);
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'start' });

      /*
      const rect = element.getBoundingClientRect();
      const offset = rect.top + window.scrollY - 100; // 100px offset
      const scrollPosition = offset + rect.height > document.body.scrollHeight ? document.body.scrollHeight : offset;
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      */
    }
  }

  /** **************************************************************************************************************** */
  /** get ROUTES string[] ******************************************************************************************** */
  /**  */

  getDefaultPageRoute(): string[] {
    return (
      this.tryGetPageRoute(Permission.Monitoring, () => this.getMonitoringRoute()) ??
      this.tryGetPageRoute(Permission.Orchestration, () => this.getEventLogsRoute()) ??
      this.tryGetPageRoute(Permission.Simulator, () => this.getSimulatorRoute(SubPageType.Simulator)) ??
      this.tryGetPageRoute(Permission.Analytics, () => this.getDetectedIncidentsRoute()) ??
      this.tryGetPageRoute(Permission.RuntimeAnalysis, () => this.getRuntimeAnalysesRoute()) ??
      this.tryGetPageRoute(Permission.ProcessReporting, () => this.getProcessesReportingRoute()) ??
      this.tryGetPageRoute(Permission.Reporting, () => this.getMonthlyReportsRoute()) ??
      []
    );
  }

  private tryGetPageRoute(permission: Permission, getRoute: () => string[]): string[] | null {
    return this.authService.hasPermission(permission) ? getRoute() : null;
  }

  private getRoute(...routeParts: string[]): string[] {
    return ['/', ...routeParts];
  }

  private getOrganizationRoute(...routeParts: string[]): string[] {
    return this.getRoute(this.getOrganizationUrlName(), ...routeParts);
  }

  // MONITORING ROUTES ----------------------------------------------------------------------------------------------
  getMonitoringRoute(pageType: PageType.Processes | PageType.Resources | PageType.Sessions | PageType.PastSchedule = PageType.Processes): string[] {
    return this.getOrganizationRoute(ModuleType.Monitoring, pageType);
  }

  getUtilizationRoute(pageType: SubPageType.Licenses | SubPageType.Resources): string[] {
    return this.getOrganizationRoute(ModuleType.Monitoring, PageType.Utilization, pageType ?? PageType.Licenses);
  }

  // ORCHESTRATION ROUTES --------------------------------------------------------------------------------------------
  getAlertsRoute(): string[] {
    return this.getOrganizationRoute(ModuleType.Orchestration, PageType.Alerts);
  }

  getEventLogsRoute(): string[] {
    return this.getOrganizationRoute(ModuleType.Orchestration, PageType.EventLog);
  }

  getOrchestrationProcessConfigurationRoute(): string[] {
    return []; // TODO: not implemented yet
  }

  getCalendarRoute(): string[] {
    return this.getOrganizationRoute(ModuleType.Orchestration, PageType.Calendar);
  }

  getSimulatorRoute(
    subPageType?:
      | SubPageType.Simulator
      | SubPageType.Processes
      | SubPageType.Sessions
      | SubPageType.LicenseUtilization
      | SubPageType.ResourceUtilization
      | SubPageType.PastSchedule
      | SubPageType.EventLog
  ): string[] {
    if (!subPageType) {
      return this.getOrganizationRoute(ModuleType.Orchestration, PageType.Simulator);
    }
    return this.getOrganizationRoute(ModuleType.Orchestration, PageType.Simulator, subPageType);
  }

  getDumpViewerRoute(subPageType?: SubPageType.DumpViewer | SubPageType.Processes | SubPageType.Sessions | SubPageType.PastSchedule | SubPageType.EventLog | SubPageType.Queues): string[] {
    if (!subPageType) {
      return this.getOrganizationRoute(ModuleType.Orchestration, PageType.DumpViewer);
    }
    return this.getOrganizationRoute(ModuleType.Orchestration, PageType.DumpViewer, subPageType);
  }

  // ANALYTICS ROUTES -----------------------------------------------------------------------------------------------
  getDetectedIncidentsRoute(): string[] {
    return this.getOrganizationRoute(ModuleType.Analytics, PageType.DetectedIncidents);
  }

  getDiagnosticDashboardRoute(): string[] {
    return this.getOrganizationRoute(ModuleType.Analytics, PageType.DiagnosticDashboard);
  }

  getRuntimeAnalysesRoute(): string[] {
    return this.getOrganizationRoute(ModuleType.Analytics, PageType.RuntimeAnalysis);
  }

  getOverviewRoute(processId: string): string[] {
    return this.getOrganizationRoute(ModuleType.Analytics, PageType.RuntimeAnalysis, SubPageType.Overview, processId);
  }

  getActionableInsightsRoute(processId: string): string[] {
    return this.getOrganizationRoute(ModuleType.Analytics, PageType.RuntimeAnalysis, SubPageType.ActionableInsights, processId);
  }

  getProcessMapRoute(processId: string): string[] {
    return this.getOrganizationRoute(ModuleType.Analytics, PageType.RuntimeAnalysis, SubPageType.ProcessMap, processId);
  }

  getVariantAnalysisRoute(processId: string): string[] {
    return this.getOrganizationRoute(ModuleType.Analytics, PageType.RuntimeAnalysis, SubPageType.VariantAnalysis, processId);
  }

  getCostAnalysisRoute(processId: string): string[] {
    return this.getOrganizationRoute(ModuleType.Analytics, PageType.RuntimeAnalysis, SubPageType.CostAnalysis, processId);
  }

  getRuntimeAnalysesNotFoundRoute(): string[] {
    return this.getOrganizationRoute(ModuleType.Analytics, PageType.RuntimeAnalysis, SubPageType.AnalysisNotFound);
  }

  // REPORTING ROUTES ------------------------------------------------------------------------------------------------
  getReportingRoute(): string[] {
    return this.getOrganizationRoute(ModuleType.Reporting);
  }

  getExecutiveOverviewRoute(): string[] {
    return this.getOrganizationRoute(ModuleType.Reporting, PageType.ExecutiveOverview);
  }

  getMonthlyReportsRoute(): string[] {
    return this.getOrganizationRoute(ModuleType.Reporting, PageType.MonthlyReports);
  }

  getBenchmarkOverviewRoute(): string[] {
    return this.getOrganizationRoute(ModuleType.Reporting, PageType.BenchmarkOverview);
  }

  getProcessesReportingRoute(): string[] {
    return this.getOrganizationRoute(ModuleType.Reporting, PageType.ProcessReporting);
  }

  getProcessReportingDetailRoute(processGroupId: string): string[] {
    return this.getOrganizationRoute(ModuleType.Reporting, PageType.ProcessReporting, processGroupId);
  }

  getProcessReportingDetailNotFoundRoute(): string[] {
    return this.getOrganizationRoute(ModuleType.Reporting, PageType.ProcessReporting, PageType.ProcessReportingNotFound);
  }

  // ORGANIZATION SETTINGS ROUTES -----------------------------------------------------------------------------------
  getOrganizationSettingsRoute(
    subPage?:
      | SubPageType.GeneralSettings
      | SubPageType.ProcessesSettings
      | SubPageType.BusinessProcessesSettings
      | SubPageType.SavingsSettings
      | SubPageType.GroupsSettings
      | SubPageType.BillingGroupsSettings
      | SubPageType.PendingSessions
      | SubPageType.OrchestrationSettings
      | SubPageType.ExternalServicesSettings
      | SubPageType.Simulator
      | SubPageType.UsersSettings
      | SubPageType.ReportingSettings
      | SubPageType.BenchmarkingSettings
      | SubPageType.NotificationsSettings
      | SubPageType.ConditionStates
  ): string[] {
    if (!subPage) {
      return this.getOrganizationRoute(ModuleType.Settings, PageType.OrganizationSettings);
    }
    return this.getOrganizationRoute(ModuleType.Settings, PageType.OrganizationSettings, subPage);
  }

  // ADMIN ROUTES --------------------------------------------------------------------------------------------------
  getAdminOrganizationsRoute(): string[] {
    return this.getRoute(ModuleType.Admin, PageType.Organizations);
  }

  getAdminUsersRoute(): string[] {
    return this.getRoute(ModuleType.Admin, PageType.Users);
  }

  getAdminLicensesRoute(): string[] {
    return this.getRoute(ModuleType.Admin, PageType.Licenses);
  }

  // AUTH ROUTES ---------------------------------------------------------------------------------------------------
  getAdminRoute(): string[] {
    return this.getRoute(ModuleType.Admin);
  }

  getLoginRoute(): string[] {
    return this.getRoute(ModuleType.Auth, PageType.Login);
  }

  getNotFoundRoute(): string[] {
    return this.getRoute(ModuleType.NotFound);
  }

  getRpaNotConnectedRoute(): string[] {
    return this.getRoute(PageType.RpaNotConnected);
  }

  getDesignSystemOverviewRoute(): string[] {
    return this.getRoute(PageType.DesignSystemOverview);
  }
  /** end of get ROUTES string[] */

  /** **************************************************************************************************************** */
  /** NAVIGATE TO page *********************************************************************************************** */
  /**  */
  async navigateToRoot(): Promise<void> {
    await this.router.navigate(this.getRootRoute());
  }

  async navigateToOtherOrganization(organizationName: string): Promise<void> {
    await this.router.navigate(this.getRoute(TextHelper.getUrlName(organizationName)));
  }

  async navigateToDefaultPage(replaceUrl = false): Promise<void> {
    const route = this.getDefaultPageRoute();
    if (route != null) {
      await this.router.navigate(route, { replaceUrl });
    } else {
      await this.navigateToLogin('User has no permissions.', true, true);
    }
  }

  // MONITORING NAVS ----------------------------------------------------------------------------------------------

  async navigateToOverview(processId: string): Promise<void> {
    await this.router.navigate(this.getOverviewRoute(processId));
  }

  async navigateToDiagnosticDashboard(queryParams?: Params, fragment: string = null): Promise<void> {
    await this.router.navigate(this.getDiagnosticDashboardRoute(), { queryParams, fragment });
  }

  async navigateToAnalyses(refresh = false): Promise<void> {
    const queryParams = refresh ? { refresh: true } : {};
    await this.router.navigate(this.getRuntimeAnalysesRoute(), { queryParams });
  }

  async navigateToVariantAnalysis(variantId?: string): Promise<void> {
    await this.router.navigate(this.getVariantAnalysisRoute(this.getProcessId()), { queryParams: { variantId: variantId ?? 'all' } });
  }

  async navigateToCostAnalysis(): Promise<void> {
    await this.router.navigate(this.getCostAnalysisRoute(this.getProcessId()));
  }

  async navigateToActionableInsight(actionableInsightId: string): Promise<void> {
    await this.router.navigate(this.getActionableInsightsRoute(this.getProcessId()), { fragment: `actionableInsight${actionableInsightId}` });
  }

  async navigateToProcessesPerformance(): Promise<void> {
    await this.router.navigate(this.getProcessesReportingRoute());
  }

  async navigateToProcessMap(processId: string): Promise<void> {
    await this.router.navigate(this.getProcessMapRoute(processId));
  }

  async navigateToRpaNotConnected(): Promise<void> {
    await this.router.navigate(this.getRpaNotConnectedRoute());
  }

  async navigateToNotFound(replaceUrl: boolean): Promise<void> {
    await this.router.navigate(this.getNotFoundRoute(), { replaceUrl });
  }

  async navigateToAnalysisNotFound(replaceUrl: boolean): Promise<void> {
    await this.router.navigate(this.getRuntimeAnalysesNotFoundRoute(), { replaceUrl });
  }

  async navigateToProcessNotFound(replaceUrl: boolean): Promise<void> {
    await this.router.navigate(this.getProcessReportingDetailNotFoundRoute(), { replaceUrl });
  }

  async navigateToAdmin(): Promise<void> {
    await this.router.navigate(this.getAdminRoute());
  }

  async navigateToOrganizationSettings(
    subPage:
      | SubPageType.BusinessProcessesSettings
      | SubPageType.SavingsSettings
      | SubPageType.GroupsSettings
      | SubPageType.BillingGroupsSettings
      | SubPageType.PendingSessions
      | SubPageType.OrchestrationSettings
      | SubPageType.ExternalServicesSettings
      | SubPageType.UsersSettings
      | SubPageType.ReportingSettings
  ): Promise<void> {
    await this.router.navigate(this.getOrganizationSettingsRoute(subPage));
  }

  async navigateToUtilization(subPage: SubPageType.Licenses | SubPageType.Resources): Promise<void> {
    await this.router.navigate(this.getUtilizationRoute(subPage));
  }

  async navigateToSimulator(
    subPage: SubPageType.Simulator | SubPageType.Processes | SubPageType.LicenseUtilization | SubPageType.ResourceUtilization | SubPageType.PastSchedule | SubPageType.EventLog,
    selectedDate?: Date
  ): Promise<void> {
    await this.router.navigate(this.getSimulatorRoute(subPage), { queryParams: ParamMapHelper.createDateParams(selectedDate) });
  }

  async navigateToAdminOrganization(name: string): Promise<void> {
    if (name) {
      await this.router.navigate(this.getAdminOrganizationsRoute(), { fragment: name });
    }
  }

  async navigateToAdminUser(email: string): Promise<void> {
    if (email) {
      await this.router.navigate(this.getAdminUsersRoute(), { fragment: email });
    }
  }

  async navigateToMonitoring(pageType: PageType.Processes | PageType.Resources | PageType.Sessions | PageType.PastSchedule = PageType.Processes, queryParams: any = null): Promise<void> {
    await this.router.navigate(this.getMonitoringRoute(pageType), { queryParams });
  }

  async navigateToDesignSystemOverview(): Promise<void> {
    await this.router.navigate(this.getDesignSystemOverviewRoute());
  }

  async navigateByUrl(url: string): Promise<void> {
    await this.router.navigateByUrl(url);
  }

  async navigateByRoute(route: string[], reload = false): Promise<void> {
    if (reload) {
      window.location.href = this.router.serializeUrl(this.router.createUrlTree(route));
      return;
    }

    await this.router.navigate(route);
  }

  async navigateToLogin(reason: string, showReason = true, useRedirectUrl = true): Promise<void> {
    this.loggingService.logInformation(`User was navigated to login. Reason: ${reason}`);
    if (showReason) {
      await this.popupService.showInfo(`Reason: ${reason}`, 'You have been logged out', 'Log in again');
    }
    let queryParams: Params;
    if (useRedirectUrl) {
      const currentUrl = window.location.href.substring(window.location.origin.length);
      if (!currentUrl.startsWith('/auth/login')) {
        queryParams = { 'redirect-url': currentUrl };
      }
    }
    await this.router.navigate(this.getLoginRoute(), { queryParams });
  }

  navigateToSessionEventLog(sessionId: string, date: Date) {
    const from = DateHelper.formatApiDateOnly(date);
    const urlTree = this.router.createUrlTree(this.getEventLogsRoute(), { queryParams: { sessionId, from, to: from } });
    const url = this.router.serializeUrl(urlTree);
    window.open(url, '_blank');
  }

  // MODIFY URL PARAMS -----------------------------------------------------------------------------------------------
  getQueryParam(paramName: string): string {
    const queryParams = this.route.snapshot.queryParamMap;
    return queryParams.get(paramName);
  }

  async removeQueryParams(keys: string[], paramMap?: ParamMap): Promise<void> {
    const queryParamMap = paramMap ?? this.route.snapshot.queryParamMap;

    if (!keys.some(k => queryParamMap.has(k))) {
      return;
    }

    const queryParams: Params = {};
    queryParamMap.keys.filter(k => !keys.includes(k)).forEach(k => (queryParams[k] = queryParamMap.get(k)));
    await this.router.navigate([], { queryParams, replaceUrl: true, relativeTo: this.route, preserveFragment: true });
  }

  async clearQueryParams(): Promise<void> {
    await this.router.navigate([], { queryParams: {}, replaceUrl: true, relativeTo: this.route, preserveFragment: true });
  }

  async addQueryParams(queryParams: Params, args?: any): Promise<void> {
    const extras = {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true,
      preserveFragment: true,
    } as NavigationExtras;

    if (args) {
      Object.assign(extras, args);
    }
    await this.router.navigate([], extras);
  }

  async addFragment(fragment: string): Promise<void> {
    await this.router.navigate([], {
      relativeTo: this.route,
      fragment,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  async removeFragment(): Promise<void> {
    await this.router.navigate([], {
      relativeTo: this.route,
      fragment: null,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  // UTILITIES --------------------------------------------------------------------------------------------------
  private getOrganizationUrlName(): string {
    return this._currentParams?.get('organizationName') ?? TextHelper.getUrlName(this.authService.user.organization);
  }

  private getProcessId(): string {
    return this._currentParams.get('processId');
  }

  private getCurrentRoute(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    return route.firstChild ? this.getCurrentRoute(route.firstChild) : route;
  }

  private trackPage(e: NavigationEnd): void {
    const duration = this._navigationStartTime > 0 ? Date.now() - this._navigationStartTime : 0;
    const url = e.urlAfterRedirects;
    let name = url.startsWith('/') ? url.slice(1) : url;
    name = name.split('?')[0];
    name = name.split('#')[0];
    const nameParts = name.split('/');
    name = nameParts[0] === 'admin' ? nameParts.join('/') : nameParts.slice(1).join('/');
    const urlParams = new URLSearchParams(window.location.search);
    const customProperties: Record<string, any> = {};
    urlParams.forEach((value: string, key: string) => {
      customProperties[key] = value;
    });
    this.loggingService.logPageView(name, e.url, customProperties, duration);
    this._navigationStartTime = 0;
  }
}
