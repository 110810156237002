import { SelectItem } from '../components/select-with-search/select-item.type';
import { Selection, SelectionHelper } from '../components/select-with-search/selection';
import { FilterDefinition, FilterFixedValue, FilterItem, FilterItemType, FilterItemValue } from './filter-definition.type';

export type FilterSelectionMap = Map<string, { items: SelectItem[]; fixedItems: SelectItem[]; selection: Selection }>;

export class FilteringHelper {
  static readonly AllItemsId = 'all';

  static createFilterSelectionMap(filterDefinition: FilterDefinition): FilterSelectionMap {
    const map: FilterSelectionMap = new Map();
    filterDefinition.filters.forEach(f => {
      if (f.type !== FilterItemType.Select && f.type !== FilterItemType.MultiSelect) {
        return;
      }
      const items = f.items.map(v => this.convertToSelectItem(v));
      const fixedItems = f.fixedItems == null ? [] : f.fixedItems.map(v => this.convertToSelectFixedItem(v));
      const selection = this.getSelectedItem(f, items.concat(fixedItems));
      map.set(f.name, { items, fixedItems, selection });
    });

    return map;
  }

  private static convertToSelectItem(filterValue: FilterItemValue): SelectItem {
    if (filterValue == null) {
      return null;
    }

    return {
      id: filterValue.id,
      label: filterValue.label + (filterValue.count != null ? ` (${filterValue.count})` : ''),
      group: filterValue.group,
      disabled: filterValue.disabled,
    } as SelectItem;
  }

  private static convertToSelectFixedItem(fixedItem: FilterFixedValue): SelectItem {
    return fixedItem != null ? SelectionHelper.createFixedItem(fixedItem.id, fixedItem.label, fixedItem.type) : null;
  }

  private static getSelectedItem(filter: FilterItem, items: SelectItem[]): Selection {
    if (filter.type === FilterItemType.Select) {
      const filterValueId = filter.value ?? FilteringHelper.AllItemsId;
      return items.find(i => i.id === filterValueId);
    }

    if (filter.type === FilterItemType.MultiSelect) {
      return filter.value != null ? items.filter(i => filter.value.includes(i.id)) : items.slice();
    }

    throw new Error(`Filter type ${filter.type} is not supported`);
  }
}
