import { Pipe, PipeTransform } from '@angular/core';
import { PlatformTextsService } from '../services/platform-texts.service';

@Pipe({ name: 'platformTexts' })
export class PlatformTextsPipe implements PipeTransform {
  constructor(private platformTexts: PlatformTextsService) {}

  transform(value: string, count?: number): string {
    return count != null ? this.platformTexts.getWithCount(value, count) : this.platformTexts.get(value);
  }
}
