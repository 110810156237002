import { Component, ElementRef, HostListener, Inject, Input, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { CurrencyHelper } from '../../helpers/currency.helper';
import { NumberHelper } from '../../helpers/number.helper';
import { MathHelper } from '../../helpers/math.helper';

@Component({
  selector: 'shared-input-number',
  templateUrl: './input-number.component.html',
  styleUrls: ['./input-number.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: InputNumberComponent },
    { provide: NG_VALIDATORS, multi: true, useExisting: InputNumberComponent },
  ],
})
export class InputNumberComponent implements ControlValueAccessor, OnInit {
  @Input() formControlName = '';
  @Input() decimals = 3;
  @Input() step = 1;
  @Input() max = 999999999999999;
  @Input() min: number | null = 0;
  @Input() maxLength = 524288;
  @Input() disabled: boolean;
  @Input() currency = false;
  @Input() width: number | 'auto' = 48;
  @Input() fixValueOnBlur = false;
  private value?: number = null;
  private lastValidValue?: number = 0;
  private touched = false;
  private onChange = (value: number) => {};
  private onTouched = () => {};
  private onValidatorChange: any = () => {};

  @ViewChild('valueInput', { static: true }) valueInput: ElementRef;
  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent): void {
    const pastedText = event.clipboardData.getData('text');
    event.preventDefault();
    setTimeout(() => (this.stringValue = pastedText), 0); // Update asynchronously to avoid interrupting the event flow
  }

  get stringValue(): string {
    return NumberHelper.stringify(this.value);
  }

  set stringValue(stringValue: string) {
    this.markAsTouched();
    this.writeValue(NumberHelper.safeValue(stringValue.trim()));
    this.onChange(this.value);
  }

  get currencySymbol(): string {
    return CurrencyHelper.currencySymbol;
  }

  get decreaseDisabled(): boolean {
    return this.disabled || this.value <= this.min;
  }

  get increaseDisabled(): boolean {
    return this.disabled || this.value >= this.max;
  }

  constructor(@Inject(LOCALE_ID) private localeId: string) {}

  ngOnInit(): void {
    this.adjustInputWidth();
  }

  onBlurEvent($event: FocusEvent): void {
    if (this.fixValueOnBlur && !NumberHelper.isValidNumber(this.value, this.min, this.max)) {
      this.writeValue(this.lastValidValue);
      this.onChange(this.value);
    }
  }

  onKeyDownEvent(event: KeyboardEvent): void {
    const keysToPreventDefault = ['Enter', 'ArrowUp', 'ArrowDown'];
    if (keysToPreventDefault.includes(event.key)) {
      event.preventDefault();
    }
  }

  onButtonKeyUpEvent(event: KeyboardEvent): void {
    if (event.key === 'ArrowUp') {
      this.increaseValue();
    } else if (event.key === 'ArrowDown') {
      this.decreaseValue();
    }
    this.adjustInputWidth();
  }

  private adjustInputWidth(): void {
    const padding = 12;
    const elm = this.valueInput.nativeElement as HTMLInputElement;

    elm.style.width = this.width === 'auto' ? 'auto' : `${Math.max(this.width, elm.value.length * 9 + padding)}px`;
  }

  increaseValue(): void {
    this.addStep(this.step);
  }

  decreaseValue(): void {
    this.addStep(-this.step);
  }

  private addStep(step: number): void {
    this.markAsTouched();
    let newValue: number;
    if (this.value == null || isNaN(this.value)) {
      newValue = this.min ?? 0;
    } else {
      newValue = (this.value + step);
    }

    newValue = Number(MathHelper.clamp(newValue, this.min, this.max).toFixed(this.decimals ?? 0));

    this.writeValue(newValue);
    this.onChange(newValue);
  }

  // ControlValueAccessor implementation
  writeValue(value: number): void {
    if (NumberHelper.isValidNumber(value, this.min, this.max)) {
      this.lastValidValue = value;
    }
    this.value = value;
  }

  registerOnChange(onChange: (value: number) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  // Validator implementation
  registerOnValidatorChange?(onValidatorChange: () => void): void {
    this.onValidatorChange = onValidatorChange;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const value: number = control.value as number | null;
    if (value == null || this.min == null || (value <= this.max && value >= this.min)) {
      return null;
    }
    return { invalidValue: { value } };
  }
}
