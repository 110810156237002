import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FeatureFlags, Permission } from '../enums/generated.enums';
import { ModuleType, PageType, SubPageType } from '../enums/page-type.enum';
import { AuthService } from '../services/auth.service';
import { NavigationService } from '../services/navigation.service';

@Injectable({ providedIn: 'root' })
export class PermissionAuthGuard {
  constructor(
    private authService: AuthService,
    private navigationService: NavigationService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.navigationService.setCurrentRoute(route);

    let canActivate = false;
    const module = this.navigationService.currentModule;
    const page = this.navigationService.currentPage;
    const subPage = this.navigationService.currentSubPage;

    const permission = this.getPermissions(module, page, subPage);
    const featureFlag = this.getFeatureFlags(subPage);
    for (const perm of permission) {
      if (this.authService.hasPermission(perm)) {
        return true;
      }
    }
    if (featureFlag) {
      canActivate = this.authService.hasFeatureFlag(featureFlag);
    }

    if (!canActivate) {
      await this.navigationService.navigateToNotFound(true);
    }
    return canActivate;
  }

  private getFeatureFlags(subPageType: SubPageType): FeatureFlags {
    switch (subPageType) {
      case SubPageType.DumpViewer:
        return FeatureFlags.DumpViewer;
      case SubPageType.BusinessProcessesSettings:
      case SubPageType.SavingsSettings:
      case SubPageType.GroupsSettings:
      case SubPageType.BenchmarkingSettings:
        return FeatureFlags.BenchmarkingSettings;
      default:
        return null;
    }
  }

  private getPermissions(module: ModuleType, page: PageType, subPage?: SubPageType): Permission[] {
    switch (module) {
      case ModuleType.Monitoring:
        return [Permission.Monitoring];
      case ModuleType.Orchestration:
        return page === PageType.Simulator ? [Permission.Simulator] : [Permission.Orchestration];
      case ModuleType.Analytics:
        return page === PageType.RuntimeAnalysis ? [Permission.RuntimeAnalysis] : [Permission.Analytics];
      case ModuleType.Reporting:
        switch (page) {
          case PageType.ProcessReporting:
            return [Permission.ProcessReporting];
          case PageType.ExecutiveOverview:
            return [Permission.Reporting];
          case PageType.BenchmarkOverview:
            return [Permission.Benchmarking];
          default:
            return [Permission.Reporting];
        }
      case ModuleType.Settings: {
        switch (subPage) {
          case SubPageType.OrchestrationSettings:
            return [Permission.Orchestration];
          case SubPageType.PendingSessions:
            return [Permission.Orchestration];
          case SubPageType.Simulator:
            return [Permission.Simulator];
          case SubPageType.BillingGroupsSettings:
            return [Permission.Reporting];
          case SubPageType.BusinessProcessesSettings:
          case SubPageType.SavingsSettings:
          case SubPageType.GroupsSettings:
            return [Permission.Benchmarking, Permission.Reporting, Permission.Analytics];
          default:
            return [Permission.OrganizationSettings];
        }
      }
      case ModuleType.Admin:
        return [Permission.SystemAdmin];
      default:
        return null;
    }
  }
}
