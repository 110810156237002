import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { DateHelper, DateTimeUnit } from '../../helpers/date.helper';
import { FormHelper } from '../../helpers/form.helper';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'shared-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: DateTimePickerComponent },
    { provide: NG_VALIDATORS, multi: true, useExisting: DateTimePickerComponent },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimePickerComponent implements ControlValueAccessor, OnChanges, Validator {
  @Input() dateTimeDelimiter = 'at';
  @Input() displayTime = true;
  @Input() minDate: Date = DateHelper.addTime(DateHelper.endOfYear(new Date()), -10, DateTimeUnit.Year);
  @Input() maxDate: Date = DateHelper.addTime(DateHelper.endOfYear(new Date()), 50, DateTimeUnit.Year);
  @Input() showErrors = true;

  form = this.formBuilder.nonNullable.group(
    {
      date: [null as Date, [this.dateValidator.bind(this)]],
      time: [{ value: null as number, disabled: !this.displayTime }, [this.timeValidator.bind(this)]],
    },
    {
      validators: [this.dateTimeRequiredValidator.bind(this)],
    },
  );

  disabled: boolean;
  private onChange = (dateTime: Date) => { };
  private onTouched = () => { };
  private onValidationChange: () => void;
  private touched = false;

  get f() {
    return this.form.controls;
  }

  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {
    this.form.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.calculateDateTime());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.displayTime) {
      FormHelper.setEnabled(this.f.time, this.displayTime);
      this.cdr.markForCheck();
    }
  }

  writeValue(dateTime: Date): void {
    if (dateTime == null) {
      this.form.reset({ date: null, time: null }, { emitEvent: false });
      this.cdr.markForCheck();
      return;
    }

    const { date, time } = DateHelper.splitDateTime(dateTime);
    this.form.setValue({ date: date, time: time }, { emitEvent: false });
    this.cdr.markForCheck();
  }

  registerOnChange(onChange: (dateTime: Date) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    FormHelper.setEnabled(this.form, !isDisabled, false);
  }

  registerOnValidatorChange(onValidatorChange: () => void): void {
    this.onValidationChange = onValidatorChange;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.form.invalid) {
      return { invalid: true };
    }
    return null;
  }

  private markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  private calculateDateTime(): void {
    this.markAsTouched();
    const { date, time } = this.form.value;
    if (time == null) {
      this.onChange(date);
      return;
    }
    const dateTime = DateHelper.joinDateTime(date, time);
    this.onChange(dateTime);
  }

  private dateValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value == null) {
      return null;
    }
    const date = new Date(control.value as Date);
    if (date != null && (date < this.minDate || date > this.maxDate)) {
      return { dateOutOfRange: true };
    }
    return null;
  }

  private timeValidator(control: AbstractControl): ValidationErrors | null {
    const time = control.value as number;
    if (time == null) {
      return null;
    }
    if (typeof time !== 'number' || isNaN(time) || time < 0 || time >= DateHelper.msPerDay) {
      return { invalidTime: true };
    }
    return null;
  }

  private dateTimeRequiredValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value == null || !this.displayTime) {
      return null;
    }

    const f = control as FormGroup<DateTimeControls>;
    if (f.value.date == null && f.value.time != null) {
      f.controls.date.setErrors({ required: true });
      return { required: true };
    } else if (f.value.date != null && f.value.time == null) {
      f.controls.time.setErrors({ required: true });
      return { required: true };
    }
    return null;
  }
}

interface DateTimeControls {
  date: FormControl<Date>;
  time: FormControl<number>;
}
