<div class="p-12 panel-wrapper">
  <div>
    <svg-icon class="color-error m-r-8" key="lock-closed" size="md" />
    <span class="color-red-dark">{{ message }}</span>
  </div>
  @if (!hideButton) {
    <button class="btn btn-primitive opaque m-l-24 m-t-8" (click)="buttonClick.emit()">
      <svg-icon class="color-green-dark m-r-8" key="lock-open" size="md" />
      Remove from quarantine
    </button>
  }
</div>
