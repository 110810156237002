<div class="flex-row flex-gap-16" [formGroup]="$any(form)">
  @for (parameter of f; track parameter.controls.name.value; let last = $last) {
    @let name = parameter.controls.name.value;
    @let type = getType(name);
    @let description = getDescription(name);
    <div [formGroup]="parameter" class="flex-col flex-gap-8">
      <label class="flex flex-center"
        >{{ name }}:
        @if (description) {
          &nbsp;<shared-info-icon [tooltip]="description" />
        }
      </label>
      <input type="hidden" formControlName="name" />
      @if (isBluePrism) {
        @switch (type) {
          @case (SessionParameterType.Date) {
            <shared-calendar-dropdown name="date" formControlName="value" [minDate]="minCalendarDate" [maxDate]="maxCalendarDate"></shared-calendar-dropdown>
          }
          @case (SessionParameterType.DateTime) {
            <shared-date-time-picker formControlName="value" [showErrors]="false" [minDate]="minCalendarDate" [maxDate]="maxCalendarDate"></shared-date-time-picker>
          }
          @case (SessionParameterType.Time) {
            <shared-time-picker formControlName="value"></shared-time-picker>
          }
          @case (SessionParameterType.TimeSpan) {
            <shared-time-span formControlName="value" />
          }
          @case (SessionParameterType.Flag) {
            <mat-radio-group formControlName="value" class="form-row-40">
              <mat-radio-button value="true">true</mat-radio-button>
              <mat-radio-button value="false">false</mat-radio-button>
            </mat-radio-group>
          }
          @case (SessionParameterType.Number) {
            <shared-input-number formControlName="value" />
          }
          @case (SessionParameterType.Password) {
            <input class="form-control" type="password" formControlName="value" autocomplete="new-password" />
          }
          @case (SessionParameterType.Text) {
            <input class="form-control" type="text" formControlName="value" />
          }
        }
      } @else {
        <input class="form-control" type="text" formControlName="value" placeholder="{{ type }}" />
      }
      @if (parameter.invalid && parameter.touched) {
        <mat-error>{{ getTypeValidationErrorMessage(type) }}</mat-error>
      }
    </div>

    <!-- add vertical delimiter after each div element except last one -->
    @if (last !== true) {
      <div class="vertical-delimiter"></div>
    }
  }
</div>
