export enum SelectItemType {
  Standard,
  All,
  Explicit,
}

export interface SelectItem {
  id: string;
  label: string;
  disabled?: boolean;
  tooltip?: string;
  group?: string;
  isFixed?: boolean;
  type?: SelectItemType;
}

export interface SelectGroup {
  label: string;
  items: SelectItem[];
}
