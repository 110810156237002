import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SubPageType } from '../../enums/page-type.enum';
import { AuthService } from '../../services/auth.service';
import { MenuService } from '../../services/menu.service';
import { NavigationService } from '../../services/navigation.service';
import { MenuItem } from '../../types/menu-section.type';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

export interface RoutingTab {
  type: SubPageType;
  label: string;
  navigate: () => Promise<void>;
}

@Component({
  selector: 'shared-routing-tabs',
  templateUrl: './routing-tabs.component.html',
  styleUrls: ['./routing-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoutingTabsComponent implements OnInit {
  @Input() useTabs = true;
  menuItem: MenuItem;
  selectedTab = 0;

  constructor(
    private menuService: MenuService,
    private navigationService: NavigationService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.menuItem = this.menuService.menuSections.map(s => s.items).reduce((a, b) => a.concat(b.filter(i => i.pageType == this.navigationService.currentPage)), [])[0];
    if (this.menuItem?.tabs == null || this.menuItem.tabs.length === 0) {
      return;
    }
    const allowedTabs = this.menuItem.tabs
      .filter(tab => !tab.isHidden)
      .filter(
        tab => (!tab.permissions && !tab.featureFlag) || (tab.permissions?.length > 0 && this.authService.hasAnyPermissions(tab.permissions)) || this.authService.hasFeatureFlag(tab.featureFlag)
      );
    this.menuItem.tabs = allowedTabs;
    this.selectedTab = this.menuItem.tabs.findIndex(t => t.subPageType == this.navigationService.currentSubPage);
  }

  async switchSelectedTab(event: MatTabChangeEvent): Promise<void> {
    this.cdr.detectChanges();
    this.selectedTab = event.index;
    await this.navigateToSelectedTab();
  }

  async switchToggleButton(event: MatButtonToggleChange): Promise<void> {
    this.cdr.detectChanges();
    this.selectedTab = event.value as number;
    await this.navigateToSelectedTab();
  }

  private async navigateToSelectedTab(subPageType?: string): Promise<void> {
    const tab = subPageType ? this.menuItem.tabs?.find(t => t.subPageType === subPageType) : this.menuItem.tabs[this.selectedTab];
    this.menuItem.route = tab.route;
    await this.navigationService.navigateByRoute(tab.route);
    if (this.menuItem.onSubPageChange) {
      this.menuItem.onSubPageChange(tab.subPageType);
    }
  }
}
