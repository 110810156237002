import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

@Component({
  selector: 'shared-time-span',
  templateUrl: './time-span.component.html',
  styleUrls: ['./time-span.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: TimeSpanComponent }],
})
export class TimeSpanComponent implements ControlValueAccessor, OnInit {
  timeSpanForm: FormGroup = this.formBuilder.group<TimeSpanFormControls>({
    days: new FormControl(0, { validators: [Validators.min(0), Validators.max(99)] }),
    hours: new FormControl(0, { validators: [Validators.min(0), Validators.max(59)] }),
    minutes: new FormControl(0, { validators: [Validators.min(0), Validators.max(59)] }),
    seconds: new FormControl(0, { validators: [Validators.min(0), Validators.max(59)] }),
  });

  timeSpanValue: any;
  isDisabled = false;
  private onChange: (value: number) => void = () => {};
  private onTouched: () => void = () => {};

  constructor(private formBuilder: FormBuilder) {}
  ngOnInit(): void {
    this.timeSpanForm.valueChanges.subscribe(value => {
      const timeSpanValue = value as { days: number; hours: number; minutes: number; seconds: number };
      const totalSeconds = (timeSpanValue.days ?? 0) * 86400 + (timeSpanValue.hours ?? 0) * 3600 + (timeSpanValue.minutes ?? 0) * 60 + (timeSpanValue.seconds ?? 0);
      this.onChange(totalSeconds * 1000);
      this.onTouched();
    });
  }

  writeValue(value: number): void {
    if (value !== undefined) {
      // Convert milliseconds to seconds
      value = value / 1000;
      const { days, hours, minutes, seconds } = {
        days: Math.floor(value / 86400),
        hours: Math.floor((value % 86400) / 3600),
        minutes: Math.floor((value % 3600) / 60),
        seconds: value % 60,
      };
      this.timeSpanForm.setValue({ days, hours, minutes, seconds }, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.timeSpanForm.disable();
    } else {
      this.timeSpanForm.enable();
    }
  }
}

interface TimeSpanFormControls {
  days: FormControl<number>;
  hours: FormControl<number>;
  minutes: FormControl<number>;
  seconds: FormControl<number>;
}
