import { Injectable } from "@angular/core";
import { BaseApiService } from "./base-api.service";
import { DumpsInfo } from "../../types/dumps-info.type";
import { DateHelper } from "../../helpers/date.helper";
import { ApiConversionHelper } from "../../helpers/api-conversion.helper";
import { Schedule } from "../../types/schedule.type";
import { BaseEntity } from "../../types/base-entity.type";
import { PendingItemsData } from "../../types/pending-tems-data.type";
import { QueueInfo } from "../../types/queue-info.type";

@Injectable({ providedIn: 'root' })
export class DumpViewerApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/dump-viewer`;
  }

  async getDumpsInfo(): Promise<DumpsInfo> {
    const dumpsInfo = await this.get<DumpsInfo>('info');
    ApiConversionHelper.convertDates(dumpsInfo, ['from', 'to', 'selectedFrom', 'selectedTo']);
    dumpsInfo?.versions.forEach(v => ApiConversionHelper.convertDates(v, ['from', 'to']));
    return dumpsInfo;
  }

  async uploadDumps(file: File): Promise<void> {
    await this.post('dumps', file);
  }

  async processDumps(from: Date, to: Date): Promise<void> {
    return await this.post(`process-dumps/${DateHelper.formatApiDateTime(from)}/${DateHelper.formatApiDateTime(to)}`, null);
  }

  async getProcessScheduleResources(date: Date): Promise<Schedule> {
    const result = await this.get<Schedule>(`schedule/resources/${DateHelper.formatApiDateOnly(date)}`);
    ApiConversionHelper.convertSchedule(result);
    return result;
  }

  async getQueueList(): Promise<QueueInfo[]> {
    const queues = await this.get<QueueInfo[]>('queue-list');
    ApiConversionHelper.convertTimeSpans(queues, ['maxLength']);
    return queues;
  }

  async getPendingItemsData(queueId: string): Promise<PendingItemsData> {
    const data = await this.get<PendingItemsData>(`pending-items-data/${queueId}`)
    ApiConversionHelper.convertDates(data, ['timestamps']);
    ApiConversionHelper.convertTimeSpans(data, ['maxLengths']);
    return data;
  }
}
