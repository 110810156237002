import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, FormControlDirective, FormControlName, FormGroupDirective, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { BehaviorSubject } from 'rxjs';
import { groupBy } from '../../helpers/array.helper';
import { SelectGroup, SelectItem, SelectItemType } from './select-item.type';
import { Selection } from './selection';

@Component({
  selector: 'shared-select-with-search',
  templateUrl: './select-with-search.component.html',
  styleUrls: ['./select-with-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: SelectWithSearchComponent }],
})
export class SelectWithSearchComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() selection: Selection;
  @Input() items: SelectItem[] = [];
  @Input() fixedItems: SelectItem[] = [];
  @Input() multiple = false;
  @Input() placeholder: string;
  @Input() searchPlaceholder = 'Search...';
  @Input() panelClass: string | string[] | Set<string> | Record<string, any>;
  @Input() selectClass: string | string[] | Set<string> | Record<string, any>;
  @Input() disabled = false;
  @Input() debounceTime = 300;
  @Output() selectionChange = new EventEmitter<any>();
  @Output() closed = new EventEmitter<any>();

  get selectionModel(): Selection {
    return this._selectionModel;
  }

  set selectionModel(value: Selection) {
    this.filterItems();
    this.updateSelection(value, false);
    this.notifyTouched();
    this.onChange(this.selectionModel);
  }

  get filter(): string {
    return this._filter;
  }

  set filter(value: string) {
    clearTimeout(this.filterTimeout);
    this.filterTimeout = setTimeout(() => {
      this._filter = value;
      this.filterItems();
    }, this.debounceTime);
  }

  get isInvalid(): boolean {
    return this.formControl?.invalid && this.formControl?.touched;
  }

  groups$ = new BehaviorSubject<SelectGroup[]>([]);

  private _selectionModel: Selection;
  private _filter = '';
  private formControl: FormControl;
  private isInitialized = false;
  private filterTimeout: NodeJS.Timeout;
  private onChange = (value: Selection): void => this.selectionChange.emit(value);
  private onTouched: () => void = null;

  constructor(
    private injector: Injector,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    try {
      const ngControl = this.injector.get(NgControl);
      if (ngControl instanceof FormControlName) {
        this.formControl = this.injector.get(FormGroupDirective).getControl(ngControl);
      } else {
        this.formControl = (ngControl as FormControlDirective).form;
      }
    } catch {
      this.formControl = this.injector.get(FormControlName, null)?.control;
    }

    this.filterItems();
    this.updateSelection(this.selection, true);
    this.isInitialized = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.isInitialized) {
      return;
    }

    if (changes.items != null) {
      this.filterItems();
    }

    this.updateSelection(this.selection, true);
  }

  onClosed(select: MatSelect): void {
    this.notifyTouched();
    this.closed.emit(this.selection);
  }

  getSelectTriggerLabel(): string {
    if (!Array.isArray(this.selectionModel)) {
      return null;
    }

    const allSelected = this.selectionModel.some(i => i.type === SelectItemType.All);
    const itemsInLabel = allSelected
      ? this.selectionModel.filter(i => i.type != null && i.type !== SelectItemType.Standard)
      : this.selectionModel.slice();

    return itemsInLabel.sort((a, b) => (+a.isFixed - +b.isFixed) || a.label.localeCompare(b.label)).map(i => i.label).join(', ');
  }

  private filterItems(): void {
    const items = this.items ?? [];
    const filterValue = this.filter.toLowerCase();
    const filteredItems = this.filter === '' ? items : items.filter(i => i.label.toLowerCase().includes(filterValue));
    const groupedItems = groupBy(filteredItems, i => i.group);
    const groups: SelectGroup[] = Array.from(groupedItems.entries())
      .map(([label, items]) => ({ label, items }))
      .sort((a, b) => a.label?.localeCompare(b.label));
    this.groups$.next(groups);
  }

  private updateSelection(selection: Selection, syncFixedItems: boolean): void {
    if (syncFixedItems) {
      selection = this.synchFixedItems(selection);
    }
    this._selectionModel = selection;
    this.selection = selection;
    this.cdr.markForCheck();
  }

  private synchFixedItems(selection: Selection): Selection {
    if (selection == null || this.items == null || this.fixedItems == null || this.multiple === false) {
      return selection;
    }

    let selectedItems = Array.isArray(selection) ? selection : [selection];
    const selectedExplicitItems = selectedItems.filter(i => i.type === SelectItemType.Explicit);
    const all = this.fixedItems.find(i => i.type === SelectItemType.All);
    if (all != null) {
      const allSelected = this.items.every(i => selectedItems.includes(i));
      selectedItems = allSelected ? [all, ...selectedExplicitItems, ...this.items] : selectedItems.filter(i => i !== all);
    }

    return selectedItems;
  }

  onFixedItemClick(item: SelectItem, selected: boolean): void {
    if (this.multiple) {
      const selectedItems = Array.isArray(this.selectionModel) ? this.selectionModel : [this.selectionModel];
      if (item.type === SelectItemType.All) {
        const selectedExplicitItems = selectedItems.filter(i => i.type === SelectItemType.Explicit);
        this.updateSelection(selected ? [item, ...selectedExplicitItems, ...this.items] : selectedExplicitItems, false);
      }
    }

    this.onChange(this.selectionModel);
  }

  onItemClick(): void {
    if (this.multiple) {
      this.updateSelection(this.selectionModel, true);
      this.onChange(this.selectionModel);
    }
  }

  // ControlValueAccessor implementation

  writeValue(value: Selection): void {
    this.updateSelection(value, true);
    this.cdr.markForCheck();
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  private notifyTouched(): void {
    this.onTouched?.();
  }
}
